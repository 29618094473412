label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
body{
  overflow-anchor: none !important;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  margin-left: 1% !important;
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.bg-dark{
  /*background-color: rgb(52, 78, 70) !important;*/
  color: #757575 !important;
  background-color:  rgb(45, 117, 131) !important;
  font-size: large;
}
.container{
  padding-top: 120px !important;
  margin-left: 20px !important;
  margin-top: 20px !important;
  min-height: fit-content !important;
  padding-bottom:2cqmin;
  position:sticky;
  min-width: 100% !important;
  margin-right: 0;
  /*min-width: fit-content !important;*/
  
}
/*h3TitlesCorrection*/
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  width: 40%;
  height: 30%;
}
.inlineLnx{
  width: 100%;
}
.fullInput{
  width: 400px;
}
.usuccess{
  border-radius: 5px;
  padding-top: 20%;
  padding-left: 34%;
  font-size: x-large;
  background-color: rgb(157, 199, 157);
  padding-bottom: 20%;
}
.ufail{
  border-radius: 5px;
  padding-top: 20%;
  padding-left: 26%;
  font-size: x-large;
  background-color: rgb(190, 142, 142);
  padding-bottom: 20%;
}
.alertDelete{
  padding-left: 7%;
  padding-top: 26%;
}
.searchBar{
  width: 20%;
  margin-bottom: 1%;
}
.navbar{
  position: fixed !important;
  margin-top: 0%;
  top: 0;
  min-width: 100%;
  min-height: 2.3rem;
  z-index: 10;
  font-size: larger;
}
body{
  width: 100%;
}
.table-header{
  background-color: rgb(219, 248, 253);
}
.fixed{
  z-index: 2;
  max-width: 400px !important;
  margin-top: -50px !important;
  position: fixed;
}
.title{
  font-family:Arial, Helvetica, sans-serif;
  color: rgb(112, 112, 112);
  margin-top: 0% !important;
}
.subtt{
  font-family:Arial, Helvetica, sans-serif;
  color: rgb(112, 112, 112);
  margin-top: 50px;
  padding-top: 10px;
  border-top: 1px solid rgb(194, 194, 194);
}
.footer{
  font-size: large;
  width: 100% !important;
  text-align: center;
  background-color: rgb(219, 219, 219);
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 10;
  margin-top: 20px;
  position: sticky;
}
.ownTickets{
  background-color: rgb(219, 219, 219);
  padding-top: 15px;
  width: 100%;
  height: 450px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding-left: 15px;
  overflow-y: scroll;
}
.flex{
  display: flex;
}
.depTickets{
  margin-top: 2%;
  margin-left: -2%;
  padding-top: 2%;
  padding-right: 2%;
  width: fit-content;
  min-width: fit-content;
  max-height: 590px;
  min-height: 590px;
  overflow-y: scroll;
  background-color: rgb(204, 204, 204);
  border-radius: 5px;
}
.flexTickets{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-right: 2%;
}
.userFlex{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-right: 2%;
}
.ticketDet{
  background-color: white;
  padding: 2%;
  width: fit-content;
  border-radius: 5px;
}
.ticketCont{
  display: flex;
  
}
.ticketChat{
  margin-left: 5%;
}
.chatWind{
  width: 100%;
  height: 400px;
  margin-left: 2%;
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll;
}
.chatInp{
  margin-top: 5%;
  height: 50px;
  width: 900px;
  margin-left: 2%;
  border-color: 1px solid rgb(165, 165, 165);
}
.button{
  width: 100%;
  background-color: rgb(180, 248, 248);
  border-radius: 5px;
  border:0ch;
}
.closingNote{
  width: 100%;
}

.buttonX{
  font-size: large;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #50e0eb;
}
.titleT{
  font-family:Arial, Helvetica, sans-serif;
  color: rgb(112, 112, 112);
  margin-top: 0% !important;
  width: 100%;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
}
.inputTickets{
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.loadinMess{
  text-align: center;
  padding-left: 40%;
  width: 100%;
}
.buttonXFilter{
  font-size: medium;
  margin-top: 20px;
  margin-right: 10px;
  min-width: 200px;
  border-radius: 5px;
  margin-bottom: 100px !important;
  background-color: #50e0eb;

}
.css-3pf6v6{
  margin-top: 10px !important;
}
.flexProfile{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: #e2e2e2;
  padding: 2%;
  width: 1700px;
  margin-left: 0%;
  height: 100%;
  margin-top: -10%;
  padding-top: 11%;
  margin-left: -3%;
}
.buttonProfileSearch{
  max-height: 33px !important;
  margin-left: 10px;
  background-color: #50e0eb;
  border-radius: 5px;
  border: 1px solid gray;
}

.flexProfiles{
  display: flex;
  background-color: #fafafa;
  border-radius: 5px;
}
.flexProfilesX{
  display: flex;
  background-color: #fafafa;
  border-radius: 5px;
  margin-top: 20px;
}

.infoTitle{
  margin-top: 20px;
  margin-left: 550px;
  color: rgb(112, 112, 112);
  height: 100%;
  padding-bottom: 20px;
}
.infoTitleBank{
  margin-top: 8px;
  margin-left: 550px;
  color: rgb(112, 112, 112);
  height: 100%;
  padding-bottom: 20px;
}
.rowsProfile{
  padding: 0px !important;
  font-size: x-large;
  width: 500px;
  border-bottom: 1px solid rgb(161, 161, 161);
}
.rowsProfileX{
  padding: 0px !important;
  font-size: x-large;
  width: 380px;
  border-bottom: 1px solid rgb(161, 161, 161);
}
.paddingProfileCell{
  margin-left: 300px !important;
  
}
.tableI{
  margin-top: 10px;
  margin-left: 20px;
 
}
.h1Profile{
  color: #bebebe;
  margin-bottom: -1% !important;
  margin-top: 1%;
}
.notesProfile{
  height: 200px;
  background-color:#fafafa;
  margin-top: 1%;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 20px;
}
.label{
  font-size: xx-large;
  color: #aaaaaa;
  margin-top: 3%;
  margin-left: 2%;
}
.label2{
  font-size: x-large;
  color: #aaaaaa;
}
.flexComp{
  margin-left: 2%;
  width: 100%;
}
.flexCompX{
  margin-left: 2%;
  width: 30%;
}
.inputEdit{
  width: 100%;
  height: 50%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.correction{
  margin-left: 2%;
}
.lone{
  width: 101%;
  height: 50%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.editButton{
  margin-top: 4%;
  border-radius: 5px;
  border: 2px solid rgb(145, 145, 145);
  background-color: #50e0eb;
  margin-bottom: 13%;
}
.calendar{
  margin-left: 53%;
  margin-top: 2%;
  display: flex;
}
.calendarL{
  margin-left: 2%;
  margin-top: 2%;
  display: flex;
}
.stepper{
  margin-top: 50px;
}
.calendarIR{
  margin-top: 30px;
}
.pClass{
  padding-left: 10px;
  margin-top: 10px;
  color: rgb(172, 172, 172);;
}
.incidentWrap{
  height: fit-content;
  margin-bottom: 230px;
}
.buttonIR{
  max-height: 33px !important;
  margin-left: 86.5%;
  margin-top: 100px;
  width: 150px;
  font-size: large;
  background-color: #50e0eb;
  border-radius: 5px;
  border: 1px solid gray;
}
.buttonIR2{
  max-height: 33px !important;
  margin-left: 75%;
  margin-right: 20px;
  margin-top: 100px;
  width: 150px;
  font-size: large;
  background-color: #50e0eb;
  border-radius: 5px;
  border: 1px solid gray;
}
.buttonIR3{
  max-height: 33px !important;
  margin-top: 100px;
  width: 150px;
  font-size: large;
  background-color: #50e0eb;
  border-radius: 5px;
  border: 1px solid gray;
}
.category{
  margin-top: 30px;
}
.pCorrection{
  margin-left: -10px;
  margin-top: 10px;
}
.topC{
  margin-top: 20px;
}
.subttH3{
  margin-top: 40px;
  color: rgb(172, 172, 172);;
}
.picturesIncidentDiv{
  min-height: 300px;
  
  background-color: #cfcfcf;
  width: 101%;
  border-radius: 5px;
  margin-bottom: 20px;
}
@keyframes color {
  0%   { color: #33CCCC; }
  20%  { color: #33CC36; }
  40%  { color: #B8CC33; }
  60%  { color: #FCCA00; }
  80%  { color: #33CC36; }
  100% { color: #33CCCC; }
}
.takePictureIncident{
  height: 100px;
  padding-top: 30px;
  margin-left: 50px;
}
.incidentCamera{
  margin-left: 30px;
  font-size: 70px !important;
  animation: color 9s infinite linear;
}
.displayGridPic{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  margin-top: 40px;
  margin-left: 20px;
}

.picture{
  margin-left: 0 !important;
  max-height: 100px;
  max-width: 150px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.h1Scorecard{
  color: #bebebe;
  margin-bottom: -1% !important;
  margin-top: -50px;
}
.selectDepotScorecard{
  width: 25%;
}
.buttonScorecardAdd{
  font-size: large;
  margin-left: 20px;
  margin-top: 55px;
  border-radius: 5px;
  border: 1px solid gray;
  max-height: 40px;
  background-color: #50e0eb;
}
.depotInput{
  height: 50px;
}
.lonePm{
  width: 100%;
  height: 43%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.inputRPm{
  width: 100%;
  height: 43% !important;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.warning{
  color: rgb(185, 2, 2);
  font-style: italic;
}
.thank{
  margin-top: 50px;
  margin-bottom: 50px;
  color: #aaaaaa;
}
.chartsContainer{
  width: 1600px;
  min-height: 250px;
  margin-top: 30px;
}
.selectStatistics{
  margin-top: -50px;
  margin-left: 10px;
  width: 25%;
}
.buttonsTelegram{
  margin-top: 19px;
  min-height: 100%;
}
.inputIncidents{
  margin-top: 20px;
  width: 30%;
}
.buttonVid{
  background-color: #4b67a0;
  color: white;
  padding-top: 0px !important;
  height: 50px;
}
.picturesIncidentsSub{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.incidentPictures{
  width: 800px;
  margin-top: 30px;
}
.buttonWcorrection{
  min-width: 130px;
}
.archive{
  height: 700px;
  max-width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.dashArch{
  height: fit-content;
  max-width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.profileArch{
  height: fit-content;
  max-width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.incidentArch{
  height: fit-content;
  max-width: 70em !important;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 200px;
}
.incidentRArch{
  height: fit-content;
  max-width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.dataArch{
  height: fit-content;
  max-width: 70em !important;
  width: 70em !important;
  margin-left: auto;
  margin-right: auto;
}
.buttonSkOn {
	position: relative;
	padding: 4px 24px;
	border-radius: 80vw;
	background-color: transparent;
	font-family: "Playfair Display", serif;
	color: #3f3f3f;
	border: solid 1px rgba(250, 250, 250, 0.3);
	overflow: hidden;
	cursor: pointer;
	transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}
.buttonSkOn .button-text {
	position: relative;
	z-index: 2;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fixed1{

}

.buttonSkOn .fill-container {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	padding-bottom: 100%;
	transform: translateY(-50%) rotate(180deg);
}
.buttonSkOn .fill-container::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(179, 179, 179);
	border-radius: 50%;
	transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
	transform: translateY(-100%);
}
.buttonSkOn:hover {
	border-color: rgb(179, 179, 179);
	transform: translateY(-4px);
}
.buttonSkOn:hover .fill-container {
	transform: translateY(-50%) rotate(0);
}
.buttonSkOn:hover .fill-container::after {
	transform: translateY(0);
}
.pClassCorrection{
  font-size: larger;
  margin-top: 15px;
}
.loneCorrection{
  width: 100% !important;
}
.bodyPArt{
  min-height: 800px;
}
.h3Cap{
  margin-top: 30px;
  font-size: x-large;
  color: rgb(128, 128, 128);
  border-bottom: 1px solid rgb(128, 128, 128);
}
.loneCap{
  width: 20% !important;
  height: 50%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.pClassC{
  color: rgb(122, 122, 122);;
}
.uploadTake{
  width: 100%;
  height: 50% !important;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #dfdfdf;
  text-align: center;
}
.termsRec{
  border: 1px solid rgb(151, 151, 151);
  width: 100%;
  height: 200px;
  padding-left: 10px;
  overflow-y: scroll;
}
.sigCanvas{
  border: 1px dashed rgb(168, 168, 168);
  width: 35%;
  height: 200px;
}
.icon{
  max-height: 300px;
  max-width: 200px;
  margin-top: 40px;
}
.iconImage{
  max-width: 80px !important;
}
.contIcons{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-right: 2%;
}
.inputRPmX{
  width: 100%;
  height: 30%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.lonePmX{
  width: 100%;
  height: 30%;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 10px;
  padding-left: 10px;
}
.lastDiv{
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid rgb(170, 170, 170);
  overflow-y: scroll;
}
.polBody{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-right: 2%;
}
.pictureHeader{
  width: 100%;
}
.pictureH{
  margin-left: -35px;
  margin-top: -500px;
  width: 102.5%;
}
.paperTop{
  width: 100%;
  height: 15%;
  background-color: #41a3a3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  text-align: left;
  padding-left: 5%;
  padding-top: 16%;
}
.paperBottom{
  padding-left: 5%;
  padding-top: 4%;
  padding-right: 5%;
  height: 85%;
  background-color: #e0e0e0;
  text-align: left;
}
.paperP{
  font-size: 18px;
  
}
.loadingBar{
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10%;
  border-bottom: 1px solid rgb(197, 197, 197);
  padding-bottom: 8%;
}
.plesaseNote{
  margin-top: 7%;
  border-radius: 5px;
  border: 2px solid rgb(182, 209, 231);
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  padding: 3%;
  background-color: rgb(235, 246, 255);
  margin-bottom: 10%;
}
.plesaseNoteRed{
  margin-top: 7%;
  border-radius: 5px;
  border: 2px solid rgb(231, 182, 182);
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  padding: 3%;
  background-color: rgb(255, 235, 235);
  margin-bottom: 10%;
}
.mainP{
  font-size: 20px;
}
.questions{
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 3%;
  padding-right: 2%;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}
.que{
  margin-bottom: 0;
}
.bodyForm{
  background-color: rgb(233, 233, 233); 
  height: fit-content; 
  margin-top: 100px; 
  margin-left: -35px; 
  text-align: center !important; 
  padding-bottom: 50px;
  margin-bottom: -50px;
}
.tables{
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 5%;
}
.tableTh{
  width: 20%;
  border: 0.5px solid rgb(160, 160, 160);
  padding: 2%;
  background-color: rgb(236, 236, 236);
}
.tableTd{
  width: 20%;
  border: 0.5px solid rgb(160, 160, 160);
  padding: 2%;
  background-color: rgb(197, 234, 255);
}
.extra{
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 10%;
  margin-bottom: 7%;
  border-bottom: 1px solid rgb(201, 201, 201);
}
.formP{
  margin-top: 3%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.formPX{
  margin-top: -3%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.PhoneInputInput{
  height: 50px !important;
  border: 2px solid rgb(218, 218, 218);
  background-color: rgb(248, 248, 248);
  margin-top: 1%;
}
.mainPX{
  margin-top: 3%;
}
#jobsLink:hover{
  text-decoration: none;
  color: #627e8b;
}

#jobsLink{
  color: #ff0000;
}
.react-calendar{
  min-width: 100%;
}
/*------------------Calendar--------------------*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: rgb(179, 179, 179);
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 2.125em !important;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0.1px solid rgb(231, 231, 231) !important;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 1em !important;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 1em !important;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #40b1ab !important;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #40b1ab !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
abbr[title]{
  text-decoration: none !important;
}
.slotsDiv{
  padding: 5%;
  border-radius: 5px;
  font-size: larger;
  background-color: #e4e4e4;
}
.pTime{
  margin-top: 0.3em;
  margin-bottom: 0px;

}
.timeDiv{
  margin-bottom: 0.4em;
  margin-left: 0.50em;
}
.slots{
  margin-top: 10%;
  display: contents;
  text-align: center;
  margin: 10%;
  padding-bottom: 5%;
}
.slotBtt{
  min-width: 100%;
  margin-top: 0.4em;
  height: 3em;
}
.lastPage{
  margin-left: 5%;
  margin-right: 5%;
}
.formTable{
  width: 100%;
  text-align: left;
  font-size: larger;
}
.formTd{
  border: 1px solid gray;
  padding: 2%;
  align-content: baseline;
  width: 60%;
}
.formTdC{
  border: 1px solid gray;
  padding: 2%;
  align-content: baseline;
  width: 30% !important;
}
.formTrC1{
  background-color: #ebebeb;
}
.formTrC2{
  background-color: #c7ebfa;
}
.nav-link{
  color: #cacaca !important;
}
.ocButton{
  background-color: white;
  border: 0px;
  width: 3%;
  padding-right: 70%;
  margin-top: -95%;
}
.correctionAdd{
  width: 154.1%;
}
.applicationTitle{
  color: white;
  margin-top: -260px;
  font-size: 80px;
  text-align: center;
}
.unhide{
  display: none;
}
.topC2{
  margin-top: -10px;
  margin-bottom: 30px;
}
.h3TitlesCorrection{
  margin-top: -30px;
  margin-left: 2%;
  color: gray;
  border-bottom: 1px solid gray;
}
.mobileInputFlex{
  display: flex;
}

.desktopImage{
  position: fixed !important;
  top: 0 !important; 
  left:-20 !important; 
  width: 100% !important; 
  height: 100% !important;
}
.spaceing{
  margin-top: 1%;
}
.fullInput1{
  width: 400px;
}
.fixed1{
  margin-bottom: 0%;
}
.fixed1{
  margin-top: -50px !important;
  position: absolute;
}
.MuiButtonBase-root{
  margin-right: 20px !important;
}
.css-10d9dml-MuiTabs-indicator{
  background-color: #ffffff !important;
}
.depotApplicationFormTitle{
  text-align: left; 
  background-color: #41a3a3;
  margin-right: 87%;
  padding-left: 1.5%;
  color: white;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.css-hz1bth-MuiDialog-container{
  width: 100% !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  width: 2500px !important;
  height: 500px;
  text-align: left;
  padding-right: 20px !important;

}
.css-ypiqx9-MuiDialogContent-root{
  overflow:  visible !important;
}
.additionalFiles{
  background-color: #eeeeee;
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-bottom: -15px !important;
  padding: 10px !important;
}
.pInsideTable{
  padding-top: 10px;
  margin-left: 20px;
}
.incGridButton{
  background-color: white;
  border: 0px;
  color: rgb(77, 122, 204);
  margin-top: 15px;
  margin-right: 20px;
  display: flex;
}
.weekNumbers{
  margin-left: 750px;
  font-weight: 700;
}
.weekS{
  margin-left: 1200px;
}
.dayPayCalendar{
  min-width: 10% !important;
  height: 10% !important;
  margin-bottom: 10px;
}
.dayPayTable{
  margin-top: 10px;
  width: 400px !important;
  font-size: larger;
  margin-right: 10px !important;
  border: 0.5px solid rgb(207, 207, 207);
}
.dayPayTable td{
  border: 0.5px solid rgb(207, 207, 207);
}
@media only screen and (max-width: 600px){
  .videoCorrection{
    margin-top: -35% !important;
  }
  .flex{
    display: contents;
  }
  .depTickets{
    margin-top: 1%;
    margin-left: 0%;
    padding-right: 3%;
    padding-bottom: 1%;
    min-width: fit-content;
    min-height: 500px;
    background-color: rgb(204, 204, 204);
    border-radius: 5px;
  }
  .flexTickets{
    display: grid;
    grid-template-columns: 1fr;
    width: fit-content;
    padding-right: 2%;
  }
  .container{
    padding-left: 0px !important;
    min-width: 100% !important;
    padding-right: 0px;
    margin-left: 0px !important;
  }
  .bodyForm{
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 0px;
  }
  .root{
    min-width:initial;
  }
  .css-5c7h6v{
    min-width: 100% !important;
    margin-left: 1%;
  }
  .footer{
    min-width: 105%;
  }
  .navbar{
    max-width: 100%;
    font-size: 14px;
  }
  .pictureHeader{
    max-width: 100%;
    margin-bottom: -87px;
  }
  .pictureH{
    margin-top: -100px;
    min-width: 109% !important;
    margin-right: 0px !important;
  }
  .correctionAdd{
    width: 100%;
  }
  .applicationTitle{
    color: white;
    margin-top: -180px;
    padding-bottom: -0px;
    font-size: 40px;
    text-align: center;
  }
  .paperP{
    font-size: 17px;
  }
  .addFormCorrectionLeft{
    margin-left: 0%;
  }
  .addFormCorrectionTop{
    margin-top: 3%;
  }
  .addFormCorrectionTop2{
    margin-top: 5%;
  }
  .formTable{
    width: 100%;
    font-size: small;
  }
  .dashArch{
    height: fit-content;
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .hide{
    display: none;
  }
  .nav-item::marker{
    display: none !important;
  }
  .unhide{
    font-size: larger !important;
    display: contents;
  }
  .mobileInput{
    min-height: 40px !important;
    margin-top: 0px;
  }
  .mobileInputFlex{
    display: flex;
  }
  .topCInputMin{
    margin-top:  -50px !important;
  }
  .topCInputPlu{
    padding-top:  30px !important;
  }
  .css-pf0kuy{
    margin-left: -10px !important;
  }
  .buttonSkOn{
    margin-bottom: 20px;
  }
  .pClass{
    padding-left: 0px;
    margin-bottom: 20px;
    margin-top: -10px;
  }
  .incidentArch{
    padding-left: 10px;
    padding-right: 20px;
  }
  .buttonSkOn{
    margin-left: 0px !important;
  }
  .pCorrection{
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .correction{
    margin-left: 0px;
    
  }
  .corrWrap{
    margin-left: -5px !important;
    width: 100%;
  }
  .pTopCorrection{
    margin-top: 10px;
  }
  .pTopCorrection2{
    margin-top: 0px;
  }
  .pCorrection2{
    margin-bottom: 0px;
  }
  .displayGridPic{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 40px;
    margin-left: 20px;
  }
  .picturesIncidentDiv{
     text-align: center;
  }
  .postCorrection{
    min-width: 50%;
  }
  .sigCanvas{
    margin-left: 2%;
    width: 98%;
  }
  .ticketCont{
    display: contents;
  }
  .ticketDet{
    margin-bottom: 20px;
  }
  .ticketChat{
    max-width: 350px;
    margin-left: -10px;
  }
  .chatWind h4{
    font-size: small !important;
  }
  .chatInp{
    width: 250px;
  }
  .userFlex{
    display: grid;
    grid-template-columns: 1fr ;
    width: 100%;
    padding-right: 2%;
  }
  .polBody{
    display: grid;
    margin-left: 20px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding-right: 2%;
  }
  .mobileImage{
    position: fixed !important;
    width: 120%;
    left: 0 !important;
   
  }
}
#background-video{

  min-height: 115%;
  min-width: 100%;
  float: left;
  margin-top: -20%;
  left: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
  -webkit-filter: blur(5px);
    -o-filter: blur(1px);
    filter: blur(1px);
  
  }
/*dashArch*/